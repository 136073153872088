import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import SuccessCopy from '../components/successCopy';

const Success = () => {
  return (
    <>
      <SEO title="Payment Confirmation" />
      <Layout>
        <SuccessCopy />
      </Layout>
    </>
  );
};

export default Success;
