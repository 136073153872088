import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import Styles from './successCopy.module.css';
import SuccessHero from './image-components/hero/successHero';

const SuccessCopy = () => {
  const [course, setCourse] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCourse(params.get('course').toUpperCase());

    if (params.get('email')) setEmail(params.get('email').toUpperCase());
  }, [course, email]);

  return (
    <>
      <SuccessHero />
      <div className={Styles.heading}>PAYMENT SUCCESSFUL</div>
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={15}>
          {course ? (
            <div>
              Congratulations on enrolling in <strong>{course} Advance</strong>!
            </div>
          ) : (
            <div>
              Congratulations on enrolling in <strong>Advance</strong>!
            </div>
          )}
          {email ? (
            <div>
              You should receive a payment confirmation email at{' '}
              <strong>{email}</strong> and your guide will reach out to you
              prior to the start date with introductions and instructions.
            </div>
          ) : (
            <div>
              You should receive a payment confirmation email and your guide
              will reach out to you prior to the start date with introductions
              and instructions.
            </div>
          )}
        </Col>
        <Col className={Styles.col_right} sm={24} md={9}>
          Please email Pastor Isaac at{' '}
          <a
            href="mailto:Isaac.chong@ekkochurch.com"
            target="_blank"
            rel="noreferrer"
          >
            isaac.chong@ekkochurch.com
          </a>{' '}
          if you have any questions.
        </Col>
      </Row>
    </>
  );
};

export default SuccessCopy;
